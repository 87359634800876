import React, { Component } from 'react';
import SETTINGS from "../settings";
import getGooglePlaces from "../webRequests/googlePlaces";
import { Typography, Paper } from "@material-ui/core";
import stateConv from "../util/stateConv";
import CircularProgress from '@material-ui/core/CircularProgress';
import ChangeMode from "../components/changeMode";

class Map extends Component {
  constructor(props) {
      super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this)
  }

state = {
  hoveredNeighborhood: null,
  selectedNeighborhood: null,
  hoveredNeighborhoodDescription: null,
  hoveredNeighborhoodTags: null,
  hoveredNeighborhoodGoogleRef: null,
  hoveredNeighborhoodHash: {},
  mapLoaded: false,
  map: null,
  markers: null,
}

LoadData(map, geoData) {
  map.data.addGeoJson(geoData)
}

SetStyles(map) {
  map.data.setStyle((feature) => {
    let fillOpacity = .15;
    let color = "rgb(30, 139, 195)"
    let selected = false;

    if (this.props.neighborhoodParams.includes(feature.getProperty("NAME")) 
    && !feature.getProperty("manuallyRemoved")) {
      selected = true;
      feature.setProperty("isSelected", selected);
      feature.setProperty("byType", true);
    }

    

    if (feature.getProperty("isHovered")) {
    color = "rgb(30, 139, 195)";
    fillOpacity = .55;
    }
    if (feature.getProperty("isSelected")) {
      color = "rgb(30, 139, 195)";
      fillOpacity = .55;
      }
   return {
    fillColor: color,
    strokeColor: "#a99136",
    fillOpacity: fillOpacity,
    strokeOpacity: .65,
    strokeWeight: 1,
   }
  })

  
}
SetListeners(map) {
  map.data.addListener('mouseover', async (event) => {
    event.feature.setProperty("isHovered", true)
    this.setState({hoveredNeighborhood: event.feature.getProperty("NAME")})
    this.setState({hoveredNeighborhoodDescription: event.feature.getProperty("description")})
    this.setState({hoveredNeighborhoodTags: event.feature.getProperty("neighborhoodTypes")});
    if (!this.state.hoveredNeighborhoodHash[event.feature.getProperty("NAME")]) {
      try {
        const oldHash = this.state.hoveredNeighborhoodHash;
        const placeRef = await getGooglePlaces(event.feature.getProperty("NAME").split(" ").join("_"), 
        event.feature.getProperty("CITY").split(" ").join("_"), stateConv[event.feature.getProperty("STATE")].split(" ").join("_").toLowerCase())
        if (placeRef.data.candidates.length > 0) {
          if (placeRef.data.candidates[0].photos) {
            const reference = placeRef.data.candidates[0].photos[0].photo_reference;

            oldHash[event.feature.getProperty("NAME")] = reference;
            this.setState({hoveredNeighborhoodGoogleRef: reference})
            this.setState({hoveredNeighborhoodHash: oldHash});
          }
          if (!placeRef.data.candidates[0].photos) {
            oldHash[event.feature.getProperty("NAME")] = "none"
            this.setState({hoveredNeighborhoodHash: oldHash})
            this.setState({hoveredNeighborhoodGoogleRef: "none"})
          }
        }
        if (!placeRef.data.candidates.length > 0) {
          oldHash[event.feature.getProperty("NAME")] = "none"
          this.setState({hoveredNeighborhoodHash: oldHash})
          this.setState({hoveredNeighborhoodGoogleRef: "none"})
        }
      }
     catch (error) {
       this.state.hoveredNeighborhoodHash[event.feature.getProperty("NAME")] = "none"
     }
    }
    if (this.state.hoveredNeighborhoodHash[event.feature.getProperty("NAME")]) {
      const oldHash = this.state.hoveredNeighborhoodHash;
      this.setState({hoveredNeighborhoodGoogleRef: oldHash[event.feature.getProperty("NAME")]});
    }
 });

 map.data.addListener('mouseout', (event) => {
  event.feature.setProperty("isHovered", false)
  this.setState({hoveredNeighborhood: null})
});
map.data.addListener('click', (event) => {

event.feature.setProperty("isSelected", !event.feature.getProperty("isSelected"))
if (event.feature.getProperty("byType")) {
  event.feature.setProperty("manuallyRemoved", true)
  this.props.callBack(event.feature.getProperty("NAME"), "remove")
}


if (event.feature.getProperty("isSelected")) {
  this.props.callBack(event.feature.getProperty("NAME"), "add")
}
if (!event.feature.getProperty("isSelected")) {
this.props.callBack(event.feature.getProperty("NAME"), "remove")
}

});
}

SetMarkers(map) {
  if (this.state.markers !== null) {
    console.log(this.state.markers);
    const oldMarkers = this.state.markers;
    for (let i = 0; i < oldMarkers.length; i++) {
      oldMarkers[i].setMap(null);
    }
  }
  let markers = [];
this.props.listings.map((property) => {
    const position = { lat: property.listing.lat, lng: property.listing.lng }
    const svgMarker = {
      path:
      "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
      fillColor: "#a99136",
      fillOpacity: 1.0,
      strokeWeight: 1.5,
      strokeColor: "white",
      rotation: 0,
      zIndex: -1,
      clickable: false,
      pointerEvents: "none",
      scale: .30,
      anchor: new window.google.maps.Point(15, 30),
    };
   const marker = new window.google.maps.Marker({ 
      position: position,
      icon: svgMarker,
      map
    });
  markers.push(marker);
  console.log(marker);
  })
this.setState({markers: markers});
this.props.setLoadStatus();
}
  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options);
      this.setState({mapLoaded: true});

      if (!this.props.geoLoading) {
        this.LoadData(map, this.props.geoJSON);
        this.SetStyles(map)
        this.SetListeners(map)

      }


    this.SetMarkers(map)
    this.setState({map: map})

  }

  componentDidMount() {
    if (!window.google) {
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${SETTINGS.APIKEYS.GOOGLE2}&map_ids=20d24c72b591046f&callback=initMap`;
      let x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important. 
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }
componentDidUpdate (prevProps) {
const map = this.state.map;

if (this.props.center[0] && this.props.center[1] && this.props.center[0] !== prevProps.center[0]) {
  map.setCenter({lat: this.props.center[0], lng: -1 * this.props.center[1]})

}

if (!this.props.geoLoading && this.props.geoLoading !== prevProps.geoLoading && this.state.mapLoaded) {

  map.data.forEach((feature) => {
    map.data.remove(feature);
});
window.google.maps.event.clearListeners(map.data, "click");
window.google.maps.event.clearListeners(map.data, "mouseover");
window.google.maps.event.clearListeners(map.data, "mouseout");
this.LoadData(map, this.props.geoJSON);
this.SetStyles(map)
this.SetListeners(map)
map.setZoom(12);
this.setState({map: map})
}

if (this.props.neighborhoodParamString !== prevProps.neighborhoodParamString) {

if (this.props.lastSetter === "checkbox") {
    console.log("setter");
  window.google.maps.event.clearListeners(map.data, "click");
  window.google.maps.event.clearListeners(map.data, "mouseover");
  window.google.maps.event.clearListeners(map.data, "mouseout");
  map.data.forEach((feature) => {
    feature.removeProperty("isSelected")
    feature.removeProperty("byType")
    feature.removeProperty("manuallyRemoved")
  })

this.SetStyles(map)
this.SetListeners(map)
}

}

if (this.props.shouldUpdateMap && this.state.mapLoaded) {
  this.SetMarkers(map);
}

}
  render() {
    return (
      <div style={{ width: "100%", position: "relative", display: "flex", height: "100%"}}>
      <div style={{ width: "100%", height: "100%"}} id={this.props.id}>
      </div>
            {this.state.hoveredNeighborhood && this.state.hoveredNeighborhoodTags ? <Paper style={{position: "absolute", paddingTop: 5, paddingBottom: 10, display: "flex", flexDirection: "column", justifyContent: "space-evenly", backgroundColor: "rgba(255, 255, 255, .90)", right: "75%", width: "25%", zIndex: 15, height: "100%",
           }}><Typography style={{fontWeight: 600, textDecoration: "underline", fontFamily: "sweet-sans-pro"}}>{this.state.hoveredNeighborhood}</Typography>
      
           <div>
           <div style={{display: "flex", fontSize: 12, maxWidth: "100%", overflow: "hidden", fontFamily: "sweet-sans-pro", flexFlow: "row-wrap"}}>
             
           </div></div>
           <Paper style={{height: 200, width: "98%", display: "flex", 
           flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
             {this.state.hoveredNeighborhoodGoogleRef && this.state.hoveredNeighborhoodGoogleRef !== "none" ?
          <img
          style={{height: "98%", width: "98%" }}
          src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${this.state.hoveredNeighborhoodGoogleRef}&key=${SETTINGS.APIKEYS.GOOGLE2}`}
          alt="placeImage"></img> : <Typography>No Imagery Available</Typography>}</Paper>
           
           <div style={{fontWeight: 600 }}>{this.state.hoveredNeighborhoodDescription}</div></Paper> : !this.state.mapLoaded ? <div style={{position: "absolute", width: "100%", height: "100%", left: 0 }}>
           <CircularProgress /></div> : null}
            </div>
    );
  }
}

export default Map;