import React, { useState } from 'react';
import { makeStyles, Toolbar, Box, Typography, Container, fade, Paper, Button } from '@material-ui/core';
import MenuEntry from "./MenuEntries";
import DehazeIcon from '@material-ui/icons/Dehaze';
import MobileMenuEntry from "./MobileMenuEntry";
import SearchIcon from '@material-ui/icons/Search';
import AutoComplete from "../components/AutoComplete";
import InputBase from '@material-ui/core/InputBase';
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  
  logo: {

    position: "relative",
    right: 40,

    width: 200,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      left: 0,
      marginLeft: 0, 
  
      },
  },
  search: {

    display: "flex",
    justifyContent: "center",
    height: 40,
    backgroundColor: fade(theme.palette.common.white, .25),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, .15),
    },
    width: '35',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      top: "10vh",
      width: "80%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    marginRight: 10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {

    display: "flex",
    justifyContent: "center",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  expand: {
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
    display: "none",
    },
  },
  iconMenuMobile: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
      },
    width: "100",
    marginRight: 5,
  },
  button: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginLeft: 60,
    borderRadius: 2,
    height: 50,
    
},
button2: {
  width: "auto",  
  height: 30,
  color: "black", 
  color: "#a99136",
height: "100%",
fontFamily: "sweet-sans-pro",
 borderRadius: 2,
 
  display: "flex",
  justifyContent: "center",


 
  


  cursor: "pointer",

},
  phoneNumber: {
    position: "absolute", 
    right: "0%", 
    bottom: "5%", 
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  leftNav: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      width: "65%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center"
      },
  },
  loginBtn: {
      width: "auto",  
      height: 30,
      color: "black", 
      color: "#a99136",
      marginTop: 5,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
   
  fontFamily: "sweet-sans-pro",
     borderRadius: 2,

      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5, 
      marginLeft: 0,
      marginRight: 5,
      border: "1px solid gray",
      
      padding: 5,
      paddingLeft: 30,
      paddingRight: 30,
      cursor: "pointer",
  },
  toolbar: {
    maxHeight: 30,
    marginLeft: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {

      },
  },

}));

const MenuSmall = (props) => {
const callback = props.updateSearch;
const classes = useStyles();

return (
   <Toolbar className={classes.toolbar}>
     <div className={classes.leftNav}>
    <Box className={classes.logo}
      href="/"
        style={{ lineHeight: 0, }}
    >
      <NavLink to="/" style={{ textDecoration: 'none' }}>
    <img 
    alt="logo"
    width="60%"
    src={require("../assets/images/Townz9.png")}
    />
    </NavLink>
    </Box>
    <Box className={classes.expand}>
{/* <MenuEntry name="Services" />
    <MenuEntry name="Properties" />
   <MenuEntry name="Research" />
    <MenuEntry name="Valuation" />
    <MenuEntry name="About" /> */}
    </Box>
    <Paper className={classes.search}>
           
           <AutoComplete
           updateSearch={callback} 
           />
         <Button className={classes.button2}><SearchIcon color="gray" /></Button>
           </Paper>
           
 
           </div>
           <Button className={classes.loginBtn}>LOG IN</Button>
</Toolbar>

  );
}
export default MenuSmall;

