import React, { useState } from 'react';
import {
    Toolbar, makeStyles, Box, Paper, Container, Typography, AppBar, Collapse, fade, Button, Hidden
} from "@material-ui/core";
import PropertyEntry from "../components/PropertyEntry";
import Footer from "../components/Footer";
import BasicDatePicker from "../components/DatePicker";
import FilterBtn from "../components/filterBtn";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Map from "../components/Map";

const useStyles = makeStyles((theme) => ({
    structure: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      padding: 0,
  },   
    },
    loadMoreButton: {
      width: "80%",
      backgroundColor: "black",
      color: "white",
      marginTop: 10,
      marginBottom: 10, 
      height: 30,

      paddingLeft: 30,
      paddingRight: 30,
    },
    filters: {
        display: "none", 
        width: "100%", 
        direction: "ltr", 
  
        
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column", 
          justifyContent: "center",
          alignItems: "center",
        },
        [theme.breakpoints.down("md")]: {
          display: "flex",
          flexDirection: "column", 
          justifyContent: "center",
          alignItems: "center",
        },
      },
      button3: {
        width: "auto",  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        fontFamily: "sweet-sans-pro",
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        border: "1px solid gray", 
        padding: 5,
        paddingLeft: 30,
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
          fontSize: 10,
        },
        [theme.breakpoints.down("md")]: {
          marginRight: 0,
        fontSize: 10,
      },
        paddingRight: 30,
        cursor: "pointer",
      },
    properties: {
        display: "flex", 
        zIndex: 10, 
        direction: "ltr", 
        boxShadow: "30px 0px 17px -20px rgba(105,105,105,0.75)", 
        maxHeight: "80vh", 
        width: "45%", 
        justifyContent: "center", 
        overflowY: "scroll", 
        flexFlow: "row wrap",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0,
            justifyContent: "space-evenly"
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          padding: 0,
          justifyContent: "space-evenly"
      }, 
    },
    map: {
        display: "flex", 
        height: "80vh", 
        flexDirection: "column", 
        width: "55%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
      },
    }
}))

const MapAndProperties = (props) => {
    const classes = useStyles();
    return (
<div className={classes.structure}>
      <Paper className={classes.properties}>
       
      {props.distributeState.propertyHolder.length === 0 && !props.distributeState.propertiesLoading ? <Typography>Sorry No Properties Are Available to Display</Typography> : props.distributeState.propertiesLoading ? <CircularProgress /> : !props.distributeState.propertiesLoading ?
        props.distributeState.propertyHolder.map((entry) => {
          const newArr = entry.listing.pictureUrls;
          if (newArr[0] !== entry.listing.pictureUrl) {
            newArr.unshift(entry.listing.pictureUrl);
          }
          return (
          <PropertyEntry style={{direction: "ltr"}}
          Address={entry.listing.city}
          type={"vacation"}
          id={entry.listing.id}
          name={entry.listing.name}
          beds={entry.listing.bedroomLabel}
          baths={entry.listing.bathroomLabel}
          neighborhood={entry.neighborhoodOrigin}
          guests={entry.listing.guestLabel}
          price={entry.pricingQuote.priceString}
          clicked={(event) => props.clickedPropertyHandler(event, 54)}
          image={entry.listing.picture_url}
          imageArray={newArr}
          />
        )}) : null }
        {/* {props.distributeState.neighborhoodParamString === "any" && props.distributeState.propertyHolder.length > 0 ? 
        <Button className={classes.loadMoreButton}>Load More Listings <KeyboardArrowDownIcon /></Button> : null} */}
          <Footer />
          </Paper>
          <div className={classes.filters}><div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}><Typography style={{fontSize: 20, fontWeight: 400, marginLeft: 25}}>{props.region} Vacation Rentals</Typography>
        <BasicDatePicker checkin={props.checkin} checkout={props.checkout} callBack={props.changeDateHandler}/></div>
        <Container style={{display: "flex", direction: "ltr", justifyContent: "center", alignItems: "center", height: 30}}>
       
<FilterBtn changeFilters={props.changeFilters} title={"Type"} filterType={"type"} transactionType={props.transactionType}/>
<FilterBtn initialMinPrice={props.initialMinPrice} initialMaxPrice={props.initialMaxPrice} changeFilters={props.changeFilters} title={"Price"} filterType={"price"} transactionType={props.transactionType}/>
<FilterBtn 
initialGuests={props.initialGuests} 
initialBeds={props.initialBeds} 
initialBedrooms={props.initialBedrooms} 
initialBathrooms={props.initialBathrooms} 
changeFilters={props.changeFilters} title={"Size"} filterType={"size"} transactionType={props.transactionType}/>
<Button className={classes.button3} >Save Search</Button>
  
</Container>
</div>
        <div className={classes.map}>
      {props.distributeState.mapCenter ?
       <Map
        id="myMap"
        mode={props.distributeState.trackedNeighborhoodGroupType}
        geoJSON={props.distributeState.geoJSON}
        center={props.distributeState.mapCenter}
        geoLoading={props.distributeState.geoLoading}
        neighborhoodParams={props.distributeState.neighborhoodParams}
        neighborhoodParamString={props.distributeState.neighborhoodParamString}
        lastSetter={props.distributeState.lastSetter}
        listings={props.distributeState.propertyHolder}
        shouldUpdateMap={props.distributeState.updateMap}
        setLoadStatus={props.updateMapLoadStatus}
        lastNeighborhood={props.distributeState.lastNeighborhood}
        callBack={props.updateChildComponentHandler}
        callBackAsGroup={props.updateChildComponentAsGroup}
        options={{
          center: { lat: props.distributeState.mapCenter[0], lng: -1 * props.distributeState.mapCenter[1] },
          zoom: 12
        }}
        onMapLoad={map => {
        }}
      /> : null}
      </div>
     
      </div>
    )
}
export default MapAndProperties;