import React from "react";
import { NavLink } from "react-router-dom";
import {
    Toolbar, makeStyles, Box, Container, Typography, AppBar
} from "@material-ui/core";
import { theme } from "./theme";
const useStyles = makeStyles((theme) => ({

    logo: {
        [theme.breakpoints.down("xs")]: {
          width: 250,
        },
        width: 250,
        marginTop: 40,
      },
      expand: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      maginTop: 20,
      },
      expand2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maginTop: 200,
        maxWidth: 200,
        },
      barApp: {
        minHeight: 150,
        backgroundColor: "#dbdbdb",
          },
          toolbar: {
            maxHeight: 75,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          },
        link: {
            maxWidth: "100%",
            textAlign: "center",
            marginTop: 40,
            color: "black",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
        }));
    
export default ()  => 
{
    const classes = useStyles();
    return (
        <AppBar position="static" color="#d2d3c9" className={classes.barApp}>
            <Container maxWidth="100%">
        <Toolbar className={classes.toolbar}>
        <Box className={classes.logo}
      href="/"
        style={{ lineHeight: 0, }}
    >
      <NavLink to="/" style={{ textDecoration: 'none' }}>
    <img 
    alt="logo"
    width="100%"
    src={require("../assets/images/Townz9.png")}
    />
    </NavLink>
    </Box>

            
    </Toolbar>
    </Container>
            </AppBar>
    )
}