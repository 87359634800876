import React, { useState } from 'react';
import {
    Toolbar, makeStyles, Box, Paper, Container, Typography, AppBar, Collapse, fade, Button, Hidden
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterBtn from "./filterBtn";
import BasicDatePicker from "./DatePicker";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import BtnComponent from "./BtnComponent";
import { MAPCOLORS } from "../mapData";
import Select from '@material-ui/core/Select';
const useStyles = makeStyles((theme) => ({
  button3: {
    width: "auto",  
    height: 30,
    color: "black", 
    color: "#a99136",
    marginTop: 5,
    fontFamily: "sweet-sans-pro",
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5, 
    marginLeft: 0,
    marginRight: 5,
    border: "1px solid gray", 
    padding: 5,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",
  },
  filters: {
    display: "flex", 
    width: "45%", 
    flexDirection: "column", 
    direction: "ltr", 
    alignItems: "flex-start", 
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  neighborhoodButtons: {
    width: "55%", 
    display: "flex", 
    alignItems: "center",  
    justifyContent: "center", 
    boxShadow: "50px 10px 17px -2px rgba(105,105,105,0.75)", 
    zIndex: 12, 
    overflowY: "scroll", 
    height: 80, 
    flexFlow: "row wrap",
    [theme.breakpoints.down("sm")]: {
     width: "100%",
      overFlowX: "scroll",
      boxShadow: "none"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
       overFlowX: "scroll",
       boxShadow: "none"
     }

  }
}))

const NeighborhoodsHolder = (props) => {
const classes = useStyles();
return (
    <div style={{ padding: 0, height: "auto", alignItems: "center",  justifyContent: "center", display: "flex", width: "100%",
    flexDirection: "row", borderBottom: "1px solid rgba(105, 105, 105, .2)",  alignItems: "center" }}>
     
      <div style={{display: "flex", width: "100%",  height: 80, alignItems: "center", justifyContent: "flex-start"}}>
        <div className={classes.filters}><div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%"}}><Typography style={{fontSize: 20, fontWeight: 400, marginLeft: 25}}>{props.region} Vacation Rentals</Typography>
        <BasicDatePicker checkin={props.checkin} checkout={props.checkout} callBack={props.changeDateHandler}/></div>
        <Container style={{display: "flex", direction: "ltr", justifyContent: "flex-start", alignItems: "center", height: 30}}>
       
<FilterBtn changeFilters={props.changeFilters} title={"Type"} filterType={"type"} transactionType={props.transactionType}/>
<FilterBtn initialMinPrice={props.initialMinPrice} initialMaxPrice={props.initialMaxPrice} changeFilters={props.changeFilters} title={"Price"} filterType={"price"} transactionType={props.transactionType}/>
<FilterBtn 
initialGuests={props.initialGuests} 
initialBeds={props.initialBeds} 
initialBedrooms={props.initialBedrooms} 
initialBathrooms={props.initialBathrooms} 
changeFilters={props.changeFilters} title={"Size"} filterType={"size"} transactionType={props.transactionType}/>
<Button className={classes.button3} >Save Search</Button>
  
</Container>
</div>
     
<div className={classes.neighborhoodButtons}>
{!props.distributeState.geoLoading ?
<FormControl variant="outlined" style={{ marginRight: 7 }}>
<Select native style={{width: "100%", height: 30, fontSize: 14}} inputProps={{name: "neighborhood-select", id: "neighborhood-1"}} onChange={props.neighborhoodTypeSelectorHandler} id="neighhoodStyle" name="Neighborhood Type">
<InputLabel htmlFor="neighborhood-1">Age</InputLabel>
<option value="none" selected disabled hidden> 
          Choose Neighborhood Type
      </option> 
  <option value="any">All Neighborhoods</option>
  <option value="Beach">Beach Neighborhoods</option>
  <option value="Hip_Modern">Hip and Modern</option>
  <option value="Drinking_Nightlife">Drinking and Nightlife</option>
  <option value="Dining"> Foodie Paradises</option>
  <option value="Upscale">Upscale</option>
  <option value="Suburbs">Suburban</option>
  <option value="Downtown">Downtown</option>
  <option value="Arts">Arts</option>
  <option value="Shopping">Shopping</option>
  <option value="Music">Music</option>
  <option value="Historic">Historic</option>
  <option value="Sports">Sports</option>
  </Select>
  </FormControl> : null}
  {props.distributeState.neighborhoodTypeSelected !== "any" && !props.distributeState.geoLoading ? 
  props.first.map((nbrhd, index) => {
    return (
        <BtnComponent checked={(event) => props.checkedNeighborhoodsHandler(event)}
        neighborhoodParams={props.distributeState.neighborhoodParams} 
        color={MAPCOLORS.colors[index]} style={{ width: 100, marginLeft: 5, marginRight: 5,}} 
         id={nbrhd.properties.NAME} neighborhood={nbrhd.properties.NAME} selected={props.distributeState.currentNeighborhood}/>
    )
  }) : props.distributeState.neighborhoodTypeSelected === "any" && !props.distributeState.geoLoading ?  props.neighborhoods.map((nbrhd, index) => {
    return (
      <BtnComponent checked={(event) => props.checkedNeighborhoodsHandler(event)}
      neighborhoodParams={props.distributeState.neighborhoodParams} 
      color={MAPCOLORS.colors[index]} style={{ width: 100, marginLeft: 5, marginRight: 5,}} 
       id={nbrhd.properties.NAME} neighborhood={nbrhd.properties.NAME} selected={props.distributeState.currentNeighborhood}/>
  )
  }) : props.distributeState.geoLoading ? <Typography>Loading Neighborhoods...</Typography> : null}
      
  </div>
      
      
      </div>
      </div>  
)
}

export default NeighborhoodsHolder;