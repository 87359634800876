import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, Typography, Container } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import DehazeIcon from '@material-ui/icons/Dehaze';
import ClearIcon from "@material-ui/icons/Clear";
import ToggleIconComponent from "../components/ToggleIconComponent";
const useStyles = makeStyles((theme) => ({
    link: {
        marginRight: 25,
        fontWeight: 600,
        color: "black",
        transition: "all .5s ease",
        "&:hover": {
        color: "#a99136",
          cursor: "pointer",
        },
    },
    menuHolder: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    menuDropDown: {
      backgroundColor: "white",
      color: "#ee844d",
      maxWidth: "100%",
    },
    menuEntries: {
      transition: "all .5s ease",
      textAlign: "center",
      color: "#a99136",
      display: "flex",
      justifyContent: "center",
      "&:hover": {
        color: "#ead983",
    },
    iconMenuMobile: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
        },
      width: "100",
    },
  },
}));
const MobileMenuEntry = (props) => {
const classes = useStyles();
const [open, setOpen] = React.useState(false);
const anchorRef = React.useRef(null);

const handleToggle = (event, props) => {
//setMenuIndex(event.target.id);
setOpen((prevOpen) => !prevOpen);
}

const handleClose = (event, props) => {

if (anchorRef.current && anchorRef.current.contains(event.target)) {
return; }
setOpen(false);
};
function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
const prevOpen = React.useRef(open);
React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current.focus();
     }
     prevOpen.current = open;

   }, [open]);

const menuLists = {
    Services: ["Brokerage", "Valuation", "Analytics"],
    Properties: ["Listings", "Sold", "Market Rents"],
    Research: ["Market Reports", "Rent Analytics", "Sale Analytics"],
    "Property Values": ["Values by Neighborhood", "Investment Performance Tracker", "Expense Benchmarks"],
    About: ["Focus", "Experience", "References"],
    };
    return (
     [<div ref={anchorRef} key="01"><ToggleIconComponent click={handleToggle} iconState={open}/></div>,
      <Popper key="02" onClick={handleToggle} open={open} style={{ width: "100%", maxWidth: "100%" }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                {...{ timeout: 1000 } }
                  {...TransitionProps}
                  
                >
                  <Container className={classes.menuDropDown}>
                    <ClickAwayListener>
                      <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                      {Object.keys(menuLists).map((m) => (
                        <NavLink style={{ textDecoration: "none", textAlign: "center" }} to={m}><MenuItem style={{ textAlign: "center" }} className={classes.menuEntries}>{m}</MenuItem></NavLink>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                    </Container>
                </Grow>
              )}
            </Popper>]
    )
    }
    export default MobileMenuEntry;