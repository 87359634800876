
const SETTINGS = {
    DOMAINREQUIREMENTS: {

        BACKEND: {
        baseUrl: "https://api.townz.io",
        "X-Access-Token": null,
        headers: null,
        },
    
        WEBSCRAPER: {
            baseUrl: "http://localhost:8001",
            "X-Access-Token": null,
            headers: null,
            },
    },

    APIKEYS: {
        GOOGLE: "AIzaSyBO_WspjC-jheeQbPUJVjX_dPcLVhOIwFs",
        GOOGLE2: "AIzaSyAOraQ-cIt4HaOhzV2Wqa52GrEucexGK9E",

}

}
export default SETTINGS