import React, { useState } from 'react';
import { makeStyles, Toolbar, Box, Typography, Container } from '@material-ui/core';
import MenuEntry from "./MenuEntries";
import DehazeIcon from '@material-ui/icons/Dehaze';
import MobileMenuEntry from "./MobileMenuEntry";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  
  logo: {
    [theme.breakpoints.down("sm")]: {
    width: 150,
    left: 0,
    marginLeft: 0, 
    marginRight: 0
    },
    marginRight: 50,
    width: 250,
    marginTop: 0,
  },
  expand: {
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
    display: "none",
    },
  },
  iconMenuMobile: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
      },
    width: "100",
    marginRight: 5,
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      position: "relative", 
      right: 20,
    },
  },
  phoneNumber: {
    position: "absolute", 
    right: "0%", 
    bottom: "5%", 
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  toolbar: {
    maxHeight: 100,
    marginLeft: 0,
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
     display: "flex",
     width: "100%",
     justifyContent: "center"
      },
  },

}));

const Menu = (menuProps) => {
const classes = useStyles();

return (
   <Toolbar className={classes.toolbar}>
    <Box className={classes.logo}
      href="/"
        style={{ lineHeight: 0, }}
    >
      <NavLink to="/" style={{ textDecoration: 'none' }}>
    <img 
    alt="logo"
    width="100%"
    className={classes.image}
    src={require("../assets/images/Townz9.png")}
    />
    </NavLink>
    </Box>
    <Box className={classes.expand}>

    </Box>
   


</Toolbar>

  );
}
export default Menu;

