import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink } from "react-router-dom";
import Menu from "./MenuStructure";
import {
    Toolbar, makeStyles, Box, Container, Typography, AppBar, Collapse, fade, Button, Hidden
} from "@material-ui/core";
import { theme } from "./theme";
const useStyles = makeStyles((theme) => ({

      barApp: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    height: 80,
    maxWidth: "100%",
      },

      login: {
          fontSize: 12,
      },
  
    toolbar: {
        maxHeight: "75",
        maxWidth: "100%",
      },
    
      holder: {
          position: "fixed",
          maxWidth: "100%",
          margin: 0,
          padding: 0,
          zIndex: 1000,
          maxWidth: "100%",
          [theme.breakpoints.down('sm')]: {
            width: "75",
            left: "0",
          },
      },
      vanish: {
          marginTop: 64,
          marginLeft: 0,
          marginRight: 0,
          padding: 0,
          backgroundColor: "transparent",
          maxWidth: "100%",
          [theme.breakpoints.down('sm')]: {
      
          },
      },
      alert: {
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          left: 0,
          right: 0,
          maxHeight: 75,
          maxWidth: "100%",
          backgroundColor: "rgba(255, 255, 255, .85)",
          borderTop: "5px solid #a99136",
      },
      button: {
          backgroundColor: "#a99136",
          "&:hover": {
          backgroundColor: "#ead983",
          },
          color: "white",
          borderRadius: 0,
          fontWeight: 800,
          marginLeft: 50
      },
   
      hideBar: {
        height: 50,
        maxWidth: "100%"
      }
  
}));
function HideOnScroll(props) {
    const { children, window } = props;
    let trigger = useScrollTrigger({ threshold: children.key, target: window ? window() : undefined });
    return (
      <Collapse appear={false} direction="down" in={!trigger}>
        {children}
      </Collapse>
    );
  }
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
export const Header = (props, menuProps) =>
{
const classes = useStyles();
    return (
        <Container className={classes.holder}>
        <AppBar height="100" color="white" className={classes.barApp}>
        <Container maxWidth="lg">
            <Menu />
        </Container>
    </AppBar>
    <Container className={classes.vanish}>
    <HideOnScroll><Container className={classes.hideBar} key="0"></Container></HideOnScroll>


    </Container>
    </Container>
    )
}