import React, { useState } from "react";
import { Typography, Button, Paper, Popper, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
    button2: {
        width: 120,  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        fontFamily: "sweet-sans-pro",
        borderRadius: 2,
        color: "black",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        border: "1px solid gray",
        padding: 5,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: "pointer",   
      },
      button: {
        width: 60,
        padding: 0,
        borderRadius: "2",
        backgroundColor: "black",
        color: "white",
        border: "1px solid black"
      },
      popper: {
        width: 120,  
        fontFamily: "sweet-sans-pro",
        minHeight: 200,
        borderRadius: 2,
        color: "black",
        display: "flex",
        flexDirection: "column",

    
        marginBottom: 5, 
        marginLeft: 0,
        backgroundColor: "white",
        border: "1px solid gray",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 1000,
        cursor: "pointer",           
      }
    }));

    const FilterBtn = (props) => {
        let initialMinPrice = 0;
        let initialMaxPrice = 0
        let initialGuests = 0;
        let initialBathrooms = 0;
        let initialBeds = 0;
        let initialBedrooms = 0;
        console.log(props.initialMinPrice, props.initialMaxPrice)
        if (props.initialMinPrice && parseInt(props.initialMinPrice > 0)) {
            initialMinPrice = parseInt(props.initialMinPrice, 10);
        }
        if (props.initialMaxPrice && parseInt(props.initialMaxPrice > 0)) {
            initialMaxPrice = parseInt(props.initialMaxPrice, 10);
        }
        if (props.initialBeds && parseInt(props.initialBeds, 10) > 0) {
            initialBeds = parseInt(props.initialBeds, 10);
        }
        if (props.initialGuests && parseInt(props.initialGuests, 10) > 0) {
            initialGuests = parseInt(props.initialGuests, 10);
        }
        if (props.initialBedrooms && parseInt(props.initialBedrooms, 10) > 0) {
            initialBedrooms = parseInt(props.initialBedrooms, 10);
        }
        if (props.initialBathrooms && parseInt(props.initialBathrooms, 10) > 0) {
            initialBathrooms = parseInt(props.initialBathrooms, 10);
        }
        const classes = useStyles();
        const [open, SetOpen] = useState(false);
        const [beds, SetBeds] = useState(initialBeds);
        const [guests, SetGuests] = useState(initialGuests);
        const [minPrice, SetMinPrice] = useState(initialMinPrice);
        const [maxPrice, SetMaxPrice] = useState(initialMaxPrice);
        const [bathrooms, SetBathrooms] = useState(initialBathrooms);
        const [bedrooms, SetBedrooms] = useState(initialBedrooms);
        const [anchorEl, SetAnchorEl] = useState(null);

        const handleClick = (event) => {
            SetOpen(!open);
            SetAnchorEl(anchorEl ? null : event.currentTarget);
        }
        const handleSubmit = (filterType) => {
            let filterObj;

            if (filterType === "size") {
                filterObj = {
                        guests: guests,
                        beds: beds,
                        bedrooms: bedrooms,
                        bathrooms: bathrooms,
                    }

            }
            if (filterType === "price") {
                filterObj = {
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                }
            }
            props.changeFilters(filterType, filterObj);
        }
        const handleBeds = (direction) => {
            if (direction === "down" && (beds - 1) >= 0) {
                SetBeds(beds - 1);
            }
            if (direction === "up" && (beds + 1) <= 25) {
                SetBeds(beds + 1);
            }
        }
        const handleBathrooms = (direction) => {
            if (direction === "down" && (bathrooms - 1) >= 0) {
                SetBathrooms(bathrooms - 1);
            }
            if (direction === "up" && (bathrooms + 1) <= 25) {
                SetBathrooms(bathrooms + 1);
            }
        }
        const handleBedrooms = (direction) => {
            if (direction === "down" && (bedrooms - 1) >= 0) {
                SetBedrooms(bedrooms - 1);
            }
            if (direction === "up" && (bedrooms + 1) <= 25) {
                SetBedrooms(bedrooms + 1);
            }
        }
        const handleGuests = (direction) => {
            if (direction === "down" && (guests - 1) >= 0) {
                SetGuests(guests - 1);
            }
            if (direction === "up" && (guests + 1) <= 25) {
                SetGuests(guests + 1);
            }
        }
        let options;
        let content;

        if (props.transactionType === "vacation" && props.filterType === "type") {
    

            content = <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
            <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <Checkbox /><Typography>Entire Place</Typography></div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <Checkbox /><Typography>Private Room</Typography></div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <Checkbox /><Typography>Hotel Room</Typography></div>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <Checkbox /><Typography>Shared Room</Typography></div>
                <Button className={classes.button}>Go</Button>
                </div>
        }

        if (props.transactionType === "vacation" && props.filterType === "price") {
                content = <div style={{display: "flex", maxWidth: "30", justifyContent: "flex-start", alignItems: "center"}}>
                    <form style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"}}>
                    <TextField defaultValue={props.initialMinPrice} onChange={(event) => SetMinPrice(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Price" variant="outlined" />
                    <TextField defaultValue={props.initialMaxPrice} onChange={(event) => SetMaxPrice(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Price" variant="outlined" />
                    </div>
                    <Button onClick={() => handleSubmit("price")} className={classes.button}>Go</Button>
                    </form>
                    </div>;

            
        }
        if (props.transactionType === "vacation" && props.filterType === "size") {
            content = 
                    <form style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center",  justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", color: "gray", width: "60%"}}><RemoveCircleOutlineIcon onClick={()=> handleGuests("down")}color="gray"/><Typography style={{marginLeft: 2, color: "black", marginRight: 2}}>{guests}</Typography><AddCircleOutlineIcon onClick={()=> handleGuests("up")}/></div>
                    <Typography style={{fontSize: 10}}>Guests</Typography>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", color: "gray", width: "60%"}}><RemoveCircleOutlineIcon onClick={()=> handleBeds("down")} color="gray"/><Typography style={{marginLeft: 2, color: "black", marginRight: 2}}>{beds}</Typography><AddCircleOutlineIcon onClick={()=> handleBeds("up")}/></div>
                    <Typography style={{fontSize: 10}}>Beds</Typography>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", color: "gray", width: "60%"}}><RemoveCircleOutlineIcon onClick={()=> handleBedrooms("down")}color="gray"/><Typography style={{marginLeft: 2, color: "black", marginRight: 2}}>{bedrooms}</Typography><AddCircleOutlineIcon onClick={()=> handleBedrooms("up")}/></div>
                    <Typography style={{fontSize: 10}}>Bedrooms</Typography>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", color: "gray", width: "60%"}}><RemoveCircleOutlineIcon onClick={()=> handleBathrooms("down")}color="gray"/><Typography style={{marginLeft: 2, color: "black", marginRight: 2}}>{bathrooms}</Typography><AddCircleOutlineIcon onClick={()=> handleBathrooms("up")}/></div>
                    <Typography style={{fontSize: 10}}>Bathrooms</Typography>
                    </div>
                    <Button onClick={() => handleSubmit("size")} className={classes.button}>Go</Button>
                    </div>
                    </form>;
        }
        return (
            <div>
            <Button key={"01"} onClick={handleClick} className={classes.button2}>
                <Typography style={{fontWeight: 0, fontFamily: "sweet-sans-pro"}}>{props.title}</Typography>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <Popper style={{zIndex: 1000 }} key={"02"} open={open} anchorEl={anchorEl}>
                <div className={classes.popper}>{content}</div>
            </Popper>
            </div>
        )
    }
export default FilterBtn;
