import React from "react";
import './App.css';
import { Header } from "./components/Header";
import Headerslideshow from "./components/Headerslideshow";
import BodyContent from "./pages/home";
import { Typography, Paper
} from "@material-ui/core";
import Footer from "./components/Footer";
import home from "./pages/home";
import about from "./pages/about";
import services from "./pages/services";
import research from "./pages/research";
import properties from "./pages/properties";
import valuation from "./pages/valuation";

import { BrowserRouter, Route, Switch } from "react-router-dom";
function App() {
  return (
    <div className="App" style={{ maxidth: "100%", margin: 0, padding: 0 }}>
      <BrowserRouter>

  <Switch>
  <Route path="/" component={home} exact style={{ maxWidth: "100%", width: "100%" }} />
  <Route path="/properties/:transactiontype/:region/:state/:neighborhood" component={properties} exact />

  </Switch>

    </BrowserRouter>
    </div>
  );
}

export default App;
