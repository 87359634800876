import React, { useState, useEffect } from "react";
import {
makeStyles, Box, Container, Slide, Typography, Paper, Button, Chip, MenuList, MenuItem
} from "@material-ui/core";
import Cities from "../util/latLong";
import { Header } from "../components/Header";
import Headerslideshow from "../components/Headerslideshow";
import Footer from "../components/Footer";
import FeaturedCity from "../components/FeaturedCity";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  
infoPanel: {
  color: "black",
  backgroundColor: "white",
  marginTop: 0,
  height: "15vh",
  width: "100%"
},
top: {
  backgroundColor: "black",
  marginBottom: 20,
},
logo: {
  [theme.breakpoints.down("xs")]: {
    width: "100%",

  },
  width: "100%",
  marginTop: 0,
  position: "relative",
  backgroundColor: "rgba(255, 255, 255, .95)",
  color: "white",
  left: 0,
},
        link: {
            marginRight: 25,
            fontWeight: 500,
            color: "black",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
          infoBox: {
            zIndex: 2,
            marginTop: 100,
            height: "65vh",
            backgroundColor: "rgba(255, 255, 255, 1.0)",
            color: "white",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            left: "0",
          
          },
  map: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
    //"-webkit-mask-image": "linear-gradient(0deg, transparent 50%, black 100%)",
   //"mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
  },
  slideshow: {
  marginTop: 20,
  maxWidth: "100%",
  position: "relative",
  padding: 0,
  margin: 0,
  },

  Typography: {
    fontFamily: "sweet-sans-pro",
  },
  slideshow2: {
    marginTop: 50,
    marginBottom: 50,
    },
    button: {
      backgroundColor: "#a99136",
      "&:hover": {
      backgroundColor: "#ead983",
      },
      color: "white",
      borderRadius: 0,
      fontWeight: 800,
      marginBottom: 50,
      
  },
  button2: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginBottom: 50,
    marginTop: 20,
},
  leftText: {
    marginTop: 0,
    maxWidth: "100%",
  },
  santabarb: {
    top: 0,
    position: "absolute",
    borderBottom: "1px solid black",
    height: "80vh",
    width: "100%",
    overflow: "hidden",
    zIndex: -1,
    opacity: 1.0,

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
   // "-webkit-mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
   // "mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
  },
  container: {
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    bottomBorder: "5px solid black",
    display: "flex",

    minHeight: "80vh",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  widget: {
    width: "25%",
    height: "auto",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 2,
    backgroundColor: "white",
    color: "black",
    marginLeft: 12.5,
    marginRight: 12.5,
    //backgroundColor: "rgba(146, 129, 122, .15)",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
      marginBottom: 20,
    },
   
 
  },
  widgetHolder: {
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    marginTop: 100,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  widgetText: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 0,
    width: "100%",
  },
  infoBox2: {
    height: "100%",
    backgroundColor: "rgba(146, 129, 122, .15)",
    color: "black",
    width: "25%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    },
  },
  slideshowImage: {
    display: "flex",
    objectFit: "cover",
    objectPosition: "top",
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  flexText: {
    display: "flex",
    justifyContent: "center",

  },
  chip: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
    },
  },
  normalChip: {
    width: 200,
   
  },
  middleChip: {
    marginLeft: 20,
    marginRight: 20,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 10,
      marginBottom: 10,
   
    },
  },
  aboutHolder: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  featured: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  text: {
    marginTop: 10,
    marginBottom: 10,
},
textDiv: {
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },

},
contentWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexDirection: "column",
    },
},
aboutHolder2: {
    minWidth: "25%",
    maxWidth: "25%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      width: "100%",
    },
},
flex: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "50%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      width: "100%",
    },
    
},
infoBox3: {
    height: "100%",
    marginTop: 10,
    backgroundColor: "rgba(146, 129, 122, .15)",
    color: "black",
    display: "flex",
    marginRight: 50,
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
        }));
const San_Francisco = [
          require("../assets/images/san_francisco.jpg")];
const San_Diego = [require("../assets/images/san_diego.jpg")];
const Seattle = [require("../assets/images/Seattle.jpg")];
const Austin = [require("../assets/images/Austin.jpg")];
const cityHolder1 = [<FeaturedCity state={"CA"} style={{transition: "1s ease in"}} direction={0} hiLo={"top"} cityArr={San_Francisco} city={"San Francisco"} />, <FeaturedCity state={"WA"} style={{transition: "1s ease in"}} hiLo={"bottom"} direction={0} cityArr={Seattle} city={"Seattle"} />];
const cityHolder2 = [<FeaturedCity state={"CA"}  style={{transition: "1s ease in"}} direction={1} hiLo={"bottom"} cityArr={San_Diego} city={"San Diego"} />, <FeaturedCity  state={"TX"} style={{transition: "1s ease in"}} hiLo={"bottom"} direction={1} cityArr={Austin} city={"Austin"} />]
export default ()  => 
{
  const [slide, setSlide] = React.useState(0);
  const classes = useStyles();
    const setSlides = () => {
      setTimeout(() => {
        if (slide < cityHolder1.length - 1) {
          setSlide(slide + 1);
        }
        if (slide === cityHolder1.length - 1) {
          setSlide(0);
        }
        }, 10000);
    }
  useEffect(() => {
  setSlides();
  }, [slide])
  return (
    <div className="App" style={{ maxidth: "100%", margin: 0, padding: 0 }}>
  <Header style={{ maxWidth: "100%" }} />,
  <Headerslideshow style={{ maxWidth: "100%" }} />
  {cityHolder1[slide]}

  {cityHolder2[slide]}

              <Footer />
  </div>
  )
};