import React, { useEffect } from "react";
import { Fade as Slideshow } from "react-slideshow-image";
import { useHistory } from "react-router";
import {
  Container, Typography, Box,
  makeStyles, Button, Paper, fade,
} from "@material-ui/core";
import AutoComplete from "../components/AutoComplete";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import  { NavLink } from "react-router-dom";
import {Redirect} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
container: {
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    bottomBorder: "5px solid black",
    display: "flex",
    minHeight: "80vh",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  button2: {
    width: "auto",  
    height: "100%",
    color: "black", 
    color: "#a99136",
  height: "100%",
  fontFamily: "sweet-sans-pro",
   borderRadius: 2,
   
    display: "flex",
    justifyContent: "center",
  
  
   
    
  
  
    cursor: "pointer",
  
  },
  santabarb: {
    top: 0,
    position: "absolute",
    borderBottom: "1px solid black",
    height: "80vh",
    width: "100%",
    overflow: "hidden",
    zIndex: -1,
    opacity: 1.0,

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },

  },
  avatar: {
     "-webkit-mask-image": "linear-gradient(0deg, transparent 1%, black 50%)",
   "mask-image": "linear-gradient(0deg, transparent 1%, black 50%)",
  },
infoBox: {
  height: "36.6vh",
  backgroundColor: "rgba(74, 69, 66, .45)",
  color: "white",
  width: "25%",
  display: "flex",
  display: "none",
  flexDirection: "column",
  justifyContent: "space-between",
  marginLeft: "0",
  [theme.breakpoints.down('sm')]: {
    display: "none",
  },

},
infoBox2: {
  display: "none",
  height: "36.6vh",
  backgroundColor: "rgba(74, 69, 66, .45)",
  color: "white",
  width: "25%",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  left: "75%",

},
  slideshowImage: {
    display: "flex",
    objectFit: "cover",
    objectPosition: "top",
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  


  vector: {
    bottomBorder: "1px solid white"
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 10,
      marginLeft: 20,
    },
    width: 15,
    height: 40,
    marginTop: 0,
    position: "relative",
  },

  search: {
    position: "relative",
    top: "8vh",
    display: "flex",
    justifyContent: "center",
    height: 50,
    backgroundColor: fade(theme.palette.common.white, .85),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, .95),
    },
    width: '25%',

    [theme.breakpoints.down("md")]: {
      height: 50,
      top: "10vh",
      padding: 0,
      width: "45%",
    },
    [theme.breakpoints.down('sm')]: {
      height: 50,
      top: "10vh",
      padding: 0,
      width: "80%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    marginRight: 10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {

    display: "flex",
    justifyContent: "center",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginLeft: 50
},
button2: {

  height: 50,
  color: "white",
  borderRadius: 0,
  fontWeight: 800,
  color: "#a99136"
},
tagline: {
  fontSize: "3em",
  marginTop: "2vh",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0vh",
    fontSize: "3em", 
    color: "white"
  },
}

}));

const slideshow = [
  require("../assets/images/townzheader.svg"),
  require("../assets/images/san_francisco.jpg"),
  require("../assets/images/san_diego.jpg"),

];

export default (props) => {
  /* eslint-disable-next-line */
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [slide, setSlide] = React.useState(0);
const anchorRef = React.useRef(null);
const history = useHistory();
const handleToggle = (event, props) => {
//setMenuIndex(event.target.id);
setOpen((prevOpen) => !prevOpen);
}

const setSlides = () => {
    setTimeout(() => {
      if (slide < slideshow.length - 1) {
        setSlide(slide + 1);
      }
      if (slide == slideshow.length - 1) {
        setSlide(0);
      }
      }, 10000);
  }
useEffect(() => {
setSlides();
}, [slide])
const updateSearch = (dataFromChild) => {
  console.log(dataFromChild)
  const arr = dataFromChild.split(", ")
  const oldPath = history.location.pathname;
  const newUrl = `properties/vacation/${arr[0].split(" ").join("_")}/${arr[1].split(" ").join("_")}/any?`;
  history.push(newUrl);
}
const handleClose = (event, props) => {
if (anchorRef !== anchorRef.current) {
  setOpen(false)
;}
if (anchorRef.current && anchorRef.current.contains(event.target)) {
return; }
setOpen(false);
};
function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
const prevOpen = React.useRef(open);
React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current.focus();
     }
     prevOpen.current = open;
     console.log(anchorRef.current);
   }, [open]);
  return (
    <Box className={classes.container}>
            <Box className={classes.santabarb}>

<img style={{ borderBottom: "5px solid black", opacity: .9 }}
  alt="img"
  src={require("../assets/images/san_francisco.jpg")}
  className={classes.slideshowImage}
/>

</Box>
      <Box className={classes.santabarb}>

              <img style={{ borderBottom: "5px solid black", opacity: .3 }}
                alt="img"
                src={require("../assets/images/townzheader.svg")}
                className={classes.slideshowImage}
              />

      </Box>

      <Typography className={classes.tagline} variant="h2" style={{  fontWeight: 800, fontFamily: "sweet-sans-pro", color: "white", fontSize: 75, marginBottom: 0}}>Find Your Part of Town</Typography>
      
      <Paper className={classes.search}>
           
      <AutoComplete
         updateSearch={updateSearch}
           />
             <Button className={classes.button2}><SearchIcon color="gray" /></Button>
           </Paper>
    </Box>
  );
};
