import SETTINGS from "../settings";
import axios from "axios";
const getAvailableProperties = async (neighborhood, city, state, requestType, filters) => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl
    console.log(neighborhood);
    const properties = await axios.get(
        `${baseUrl}/vacation/airbnb/${neighborhood}/${city}/${state}/${requestType}?${filters}`);

    return properties;
}

export default getAvailableProperties